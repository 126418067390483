
import { Component, Prop, Vue } from "vue-property-decorator";
import type { Seller } from "@/store/modules/seller.store";
import { bus } from "@/main";
import { formatDate } from "@/utils/formatters";
import { ActivitySeller } from "@/store/modules/activity-seller.store";

@Component({
    components: {
        tag: () => import("@/components/general/tag.vue"),
    },
})
export default class SellerInfo extends Vue {
    @Prop() seller!: Seller & { relation_id?: Number };

    @Prop() activitySeller!: ActivitySeller;

    //resendActivityInvite

    bus = bus;

    formatDate = formatDate;
}
